const oddTransferFields = {
  accidentFields: {
    trafficaccident: {
      aghk: null,
      agpz: null,
      subproject_id: null,
      created_at: 'datetime',
      detection_employee_id: null,
      description: null,
      normative_doc: null,
      contractor_id: null,
      subcontractor_id: null,
      subsubcontractor_id: null,
      corrective_actions: null,
      elimination_date: null,
      date_end: null
    },
    intruders: {
      name: 'guilty_name',
      birthday: 'guilty_birthday',
      position: 'guilty_position',
      organization_id: 'guilty_organization_id',
      vehicle_id: null,
      vehicle_is_removed: null,
      model: null,
      vehicle_type: null,
      vehicle_class: null
    }
  },
  closureFields: {
    road_block: {
      aghk: null,
      agpz: null,
      subproject_id: null,
      created_at: 'datetime',
      contractor_id: null,
      subcontractor_id: null
    }
  }
}

export const setMarkItems = (markItems, event = {}) => {
  const { event_class } = event

  if (event_class?.name === 'ДТП') {
    return getAccidentMarkItems(markItems)
  } else if (event_class?.name === 'Недостатки') {
    return getClosureMarkItems(markItems)
  }
}

const markItemsIterator = (markItems, list) => {
  if (!list) return

  Object.keys(list).forEach(e => {
    const key = list[e] || e

    markItems.push(key)
  })
}

const getAccidentMarkItems = (markItems) => {
  const { accidentFields } = oddTransferFields
  const blocks = ['trafficaccident', 'intruders', 'corrective_actions']

  blocks.forEach(e => {
    markItemsIterator(markItems, accidentFields[e])
  })
}

const getClosureMarkItems = (markItems) => {
  const { closureFields } = oddTransferFields
  const blocks = ['road_block']

  blocks.forEach(e => {
    markItemsIterator(markItems, closureFields[e])
  })
}
