<template>
  <div
    v-if="source"
    class="violations-editor">
    <div class="violations-editor__header">
      <r-back-button @back="back" />
      <r-title>Редактировать нарушение</r-title>
    </div>
    <r-tabs @input="viewTab = $event">
      <r-tab
        v-for="tab in tabs"
        :id="tab.label"
        :key="tab.label"
        :name="tab.title"
        :active="viewTab === tab" />
    </r-tabs>
    <div class="violations-editor__attribute-container">
      <violations-tip v-if="source.event_id && viewTab !== 'act' && viewTab !== 'filesComments'" />

      <violations-card
        v-if="source.event_id && viewTab === 'main'"
        :source="source" />

      <attributes-list
        v-if="viewTab === 'main'"
        :model="violationTypeField"
        :source="source"
        is-editing
        :related="related"
        @hasChanges="hasChanges = true" />

      <attributes-list
        v-if="viewTab !== 'act' && viewTab !== 'filesComments'"
        :model="tabFields"
        :source="source"
        :related="related"
        :mark-items="markItems"
        is-editing
        @hasChanges="hasChanges = true" />

      <violations-act
        v-if="viewTab === 'act'"
        :source="source"
        @has-changes="hasChanges = true" />

      <resources-block
        v-if="viewTab === 'filesComments'"
        :id="source.id || ''"
        :source_id="source_id" />
    </div>
    <save-block
      :disabled="!hasChanges"
      @save="save" />
    <r-delete-button
      mini
      @delete="deleteViolation" />
  </div>
</template>

<script>
import { tabs, config, fields, actFields, violationTypeField } from './config'
import { setMarkItems } from './helpers'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    attributesList: () => import('@/components/globals/r-modal-elements/attributes-list'),
    saveBlock: () => import('./save-block/save-block'),
    resourcesBlock: () => import('./resources-block/resources-block'),
    violationsCard: () => import('./violations-editor/violations-card'),
    violationsTip: () => import('./violations-editor/violations-tip'),
    violationsAct: () => import('./violations-editor/violations-act')
  },
  data () {
    return {
      id: null,
      source: null,
      clone: null,
      hasChanges: false,
      oddEvent: {},
      viewTab: 'main',
      blocks: [
        { tab: 'main', field: 'mainFields', block: false },
        { tab: 'act', field: 'mainActFields', block: true },
        {
          tab: 'act',
          field: 'prescriptionActFields',
          block: true,
          subtitle: 'Акт-предписание'
        },
        {
          tab: 'act',
          field: 'violationActFields',
          block: true,
          subtitle: 'Акт-нарушение'
        }
      ],
      employeesArrayFields: [
        'violation_appointed_employees',
        'violation_representatives_employees'
      ],
      markItems: [],
      violationTypeField,
      tabs,
      fields
    }
  },
  computed: {
    activeApplicationId() {
      return this.source?.id
    },
    tabFields() {
      return fields.filter(f => f.tab === this.viewTab)
    },
    disabledTab() {
      if (!this.noViolator) return false
      if (this.viewTab === 'violator') return true
      return false
    },
    source_id () {
      return this.$store.state.violations.source_id
    },
    related () {
      return this.$store.state.violations.related
    },
    fieldsObject () {
      const { fields } = this
      return {
        mainFields: fields.filter(f => f.tab === 'main'),
        mainActFields: fields.filter(f => f.actBlock === 'main'),
        prescriptionActFields: fields.filter(
          f => f.actBlock === 'prescription'
        ),
        violationActFields: fields.filter(f => f.actBlock === 'violation')
      }
    },
    noViolator() {
      return this.oddEvent?.event_class?.name === 'Недостатки'
    }
  },
  async created () {
    this.id = this.$route.query.id

    if (this.id) {
      await this.load()
      this.$router.replace({
        query: {}
      })
    } else {
      this.back()
    }
  },
  methods: {
    async load () {
      try {
        const { related, id, $store, source_id } = this
        const idConfig = {
          ...config,
          where: {
            field: 'id',
            op: '=',
            value: id
          }
        }
        const url = `objectInfo/${source_id}?config=${JSON.stringify(idConfig)}`

        const { data } = await $store.dispatch('GET_REQUEST', {
          url
        })
        this.source = { ...data[id], related }
        setMarkItems(this.markItems, this.oddEvent)

        if (this.source.vehicle_id) {
          const { vehicle } = this.source

          this.source.model = vehicle?.model?.name
          this.source.vehicle_type = vehicle?.vehicle_type?.name
          this.source.vehicle_class = vehicle?.vehicle_class?.name
        }
        this.clone = cloneDeep(this.source)

        if (this.source.event_id) {
          this.oddEvent = this.related.event?.data?.find(
            e => e.id === this.source.event_id
          )
          setMarkItems(this.markItems, this.oddEvent)
        }
      } catch (e) {
        console.log(e)
      }
    },
    formatArrayFields () {
      this.employeesArrayFields.forEach(f => {
        this.source[f] = this.source[f]?.map(s => {
          if (s) {
            return s.employee_id
          }
        })
      })
    },
    async save () {
      const { source, source_id } = this

      const url = `objectInfo/${source_id}`

      const data = {}
      fields.forEach(f => {
        if (f.read_only) return
        data[f.model] = source[f.model]
      })
      actFields.forEach(f => {
        if (f.read_only) return
        if (f.type === 'datetime') {
          data[f.model] = source[f.model] ? this.$rDate.format(source[f.model], 'iso') : source[f.model]
        } else {
          data[f.model] = source[f.model]
        }
      })
      data.no = source.no
      data.id = source.id

      this.employeesArrayFields.forEach(field => {
        const disabled =
            this.source[field]?.map(e => {
              if (e) {
                return {
                  id: e.id,
                  disabled: true
                }
              }
            }) || []
        const newEmployees = data[field]?.map(e => {
          if (e) {
            return {
              employee_id: e.id || e
            }
          }
        }) || []
        data[field] = [...disabled, ...newEmployees]
      })
      data.status_id = 2

      try {
        await this.$store.dispatch('POST_REQUEST', { url, data })
      } catch (e) {
        console.log(e)
      } finally {
        this.back()
      }
    },
    async deleteViolation() {
      const { source, source_id, $store } = this

      try {
        await $store.dispatch('POST_REQUEST', {
          url: `objectInfo/${source_id}`,
          data: { id: source.id, disabled: true }
        })
        this.back()
      } catch (e) {
        console.log(e)
      }
    },
    back () {
      this.$router.push('/violations/list')
    }
  }
}
</script>

<style lang="scss">
.violations-editor {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  grid-gap: 1rem;
  padding: 0 0.5rem;

  height: 100%;
  justify-content: stretch;
  position: relative;
  align-content: start;

  &__attribute-container {
    height: 100%;
    overflow: auto;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1rem;
  }

  &__header {
    position: relative;
    padding: 0.5rem;

    .r-title {
      text-align: center;
    }

    button {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &__tabs.el-radio-group {
    display: flex;
    z-index: 2;
    height: 36px;

    .el-radio-button {
      flex: 1;

      .el-radio-button__inner {
        height: 36px !important;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-auto-flow: column;
        grid-gap: 0.5rem;
        padding: 0;
        background-color: var(--bg_containers);
        border: 0;
        box-shadow: none !important;
        font-size: 14px;
        color: var(--text_primary);
        font-weight: 400;
      }

      &.is-active {
        .el-radio-button__inner {
          background-color: var(--accent_active) !important;
        }
      }
    }
  }
}
</style>
